import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: HomeView
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        components: {
          dashboard: () => import('../components/DashboardItem.vue')
        }
      },
      {
        path: '/profile-account',
        name: 'ProfileAccount',
        components: {
          dashboard: () => import('../components/ProfileItem.vue')
        }
      },
      {
        path: '/sales',
        name: 'Sales',
        components: {
          dashboard: () => import('../views/SalesView.vue')
        }
      },
      {
        path: '/record-sales',
        name: 'RecordSales',
        components: {
          dashboard: () => import('../views/RecordInvoice.vue')
        }
      },
      {
        path: '/preview-sales',
        name: 'PreviewSales',
        components: {
          dashboard: () => import('../views/PreviewInvoice.vue')
        }
      },
      {
        path: '/truck-follow-up',
        name: 'TruckFollowingUp',
        components: {
          dashboard: () => import('../views/TruckFollowingUp.vue')
        }
      },
      {
        path: '/complains',
        name: 'Complains',
        components: {
          dashboard: () => import('../views/ComplainsView.vue')
        }
      },
      {
        path: '/roles-permissions',
        name: 'RolesPermissions',
        components: {
          dashboard: () => import('../views/RolePermissionsView.vue')
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
